<template>
  <div>
    <Navigation />
    <div class="pa-5 flex space-between">
      <SupplierFilters />
      <ElButton @click="$refs.supplierDialog.add()" type="primary"
        >Adauga Furnizor</ElButton
      >
    </div>
    <!-- table -->
    <ResourceTable
      :query="query"
      :filter="filter"
      :default-sort="{ prop: 'data.name' }"
    >
      <ElTableColumn type="index" width="50" />
      <ElTableColumn prop="data.name" label="Nume" sortable />
      <ElTableColumn label="Creare" sortable sort-by="data.createdAt.seconds">
        <TimeAgo
          slot-scope="{ row }"
          v-if="row.data.createdAt"
          :date="row.data.createdAt.toDate()"
        />
      </ElTableColumn>
      <ElTableColumn
        label="Actualizare"
        sortable
        sort-by="data.updatedAt.seconds"
      >
        <TimeAgo
          slot-scope="{ row }"
          v-if="row.data.updatedAt"
          :date="row.data.updatedAt.toDate()"
        />
      </ElTableColumn>
      <ElTableColumn align="right">
        <template slot-scope="{ row }">
          <ElButton @click="goToMaterials(row.id)" size="mini"
            >Materiale</ElButton
          >
          <ElButton @click="$refs.supplierDialog.edit(row.id)" size="mini"
            >Modifica</ElButton
          >
        </template>
      </ElTableColumn>
    </ResourceTable>
    <!-- dialogs -->
    <SupplierDialog ref="supplierDialog" />
  </div>
</template>

<script>
import FiltersMixin from "@/mixins/FiltersMixin";
import SupplierDialog from "@/components/suppliers/SupplierDialog";
import SupplierFilters from "@/components/suppliers/SupplierFilters";

export default {
  mixins: [FiltersMixin()],
  components: {
    SupplierDialog,
    SupplierFilters
  },
  computed: {
    query() {
      return this.$firestore().collection("suppliers");
    }
  },
  methods: {
    filter(row) {
      const { filters } = this;
      if (filters.name) {
        if (row.data.name.match(new RegExp(filters.name, "i")) === null) {
          return false;
        }
      }
      return true;
    },
    goToMaterials(supplier) {
      this.$router.push({ path: "/materials", query: { supplier } });
    }
  }
};
</script>
